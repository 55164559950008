<template>
  <div>
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
    <Section7 />
    <Section6 />
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from "../components/home/Section1.vue";
import Section2 from "../components/home/Section2.vue";
import Section3 from "../components/home/Section3.vue";
import Section4 from "../components/home/Section4.vue";
import Section5 from "../components/home/Section5.vue";
import Section6 from "../components/home/Section6.vue";
import Section7 from "../components/home/Section7.vue";

export default {
  name: "Home",
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Section7,
  },
};
</script>

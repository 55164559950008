<template>
  <div class="white">
    <div class="page-container">
      <v-container fluid class="py-5">
        <v-row align="center">
          <v-col cols="12" md="auto" class="d-flex align-center">
            <img
              src="../assets/PlatformLogo-01.svg"
              class="my-auto"
              width="100"
              height="auto"
            />
            <span class="fa-20 ml-2"> {{ $t("contact.title") }} </span>
          </v-col>
        </v-row>

        <v-row align-content="stretch">
          <v-col cols="12">
            {{ $t("contact.description") }}
          </v-col>
          <v-col cols="12">
            <v-icon>mdi-email-outline</v-icon> {{ $t("contact.email") }} :
            {{ email }}
          </v-col>
          <v-col cols="12">
            <v-icon>mdi-cellphone</v-icon> {{ $t("contact.mobile") }} :
            {{ mobile }}
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const lang = localStorage.getItem("lang") || "ar";
    return {
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
      language: lang,
      email: "enquiry@moaakad.gov.iq",
      mobile: "+96456789900823",
    };
  },
};
</script>
<style scoped>
.circular-image-container {
  width: 100px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  overflow: hidden;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid grey; /* Add a grey border around the circular image */
  filter: grayscale(100%); /* Apply grayscale filter by default */
  transition: 0.3s;
}

.circular-image-container:hover {
  filter: grayscale(40%); /* Remove the grayscale filter on hover */
}

.logo {
  width: 80%; /* Adjust the width as needed */
  height: auto;
  border-radius: 50%;
  display: block; /* Ensures the image is centered within the circular container */
  margin: auto; /* Center the image horizontally */
}
</style>

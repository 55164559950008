var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"page-container"},[_c('v-container',{staticClass:"py-3 pb-10",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"auto"}},[_c('img',{staticClass:"my-auto",attrs:{"src":require("../../assets/PlatformLogo-01.svg"),"width":"100","height":"auto"}}),_c('span',{staticClass:"fa-20 ml-2"},[_vm._v(" "+_vm._s(_vm.privacyPolicy.title)+" ")])])],1),_c('v-row',[_c('v-col',[_c('v-sheet',{staticClass:"pa-6"},[_c('p',[_vm._v(_vm._s(_vm.privacyPolicy.lastUpdated))]),_c('p',[_vm._v(" "+_vm._s(_vm.privacyPolicy.description)+" ")]),_c('p',[_c('v-icon',[_vm._v("mdi-information-outline")]),_vm._v(" "+_vm._s(_vm.privacyPolicy.usageAgreement)+" ")],1),_c('h3',[_vm._v(" "+_vm._s(_vm.privacyPolicy.interpretationAndDefinitions.interpretation)+" ")]),_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.privacyPolicy.interpretationAndDefinitions.definitions.title)+" ")]),_c('ul',{staticStyle:{"list-style-type":"disc"}},_vm._l((_vm.privacyPolicy
                  .interpretationAndDefinitions.definitions.data),function(definition,definitionData){return _c('li',{key:definitionData,staticClass:"mx-5"},[_c('strong',[_vm._v(_vm._s(definition.title)+" : ")]),_vm._v(" "+_vm._s(definition.text)+" ")])}),0),_c('p',{staticClass:"mt-5 fa-20"},[_c('strong',[_vm._v(_vm._s(_vm.privacyPolicy.collectingAndUsingPersonalData .typesOfDataCollected.title)+" ")]),_vm._v(" | "),_c('span',{staticClass:"fa-16"},[_c('v-icon',[_vm._v("mdi-information-outline")]),_vm._v(" "+_vm._s(_vm.privacyPolicy.collectingAndUsingPersonalData .typesOfDataCollected.usageData)+" ")],1)]),_c('ul',{staticStyle:{"list-style-type":"disc"}},_vm._l((_vm.privacyPolicy
                  .collectingAndUsingPersonalData.typesOfDataCollected
                  .personalData),function(dataPoint){return _c('li',{key:dataPoint,staticClass:"mx-5"},[_vm._v(" "+_vm._s(dataPoint)+" ")])}),0),_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.privacyPolicy.collectingAndUsingPersonalData .trackingTechnologiesAndCookies.description)+" ")]),_c('ul',{staticStyle:{"list-style-type":"disc"}},_vm._l((_vm.privacyPolicy
                  .collectingAndUsingPersonalData
                  .trackingTechnologiesAndCookies.technologiesUsed),function(technology){return _c('li',{key:technology,staticClass:"mx-5"},[_vm._v(" "+_vm._s(technology)+" ")])}),0),_c('ul',{staticStyle:{"list-style-type":"disc"}},_vm._l((_vm.privacyPolicy
                  .collectingAndUsingPersonalData
                  .trackingTechnologiesAndCookies.cookiesInformation),function(cookieInfo){return _c('li',{key:cookieInfo.type,staticClass:"mx-5"},[_c('span',[_vm._v(_vm._s(cookieInfo.type))]),_vm._v(": "+_vm._s(cookieInfo.purpose)+" ")])}),0),_c('ul',{staticStyle:{"list-style-type":"disc"}},_vm._l((_vm.privacyPolicy
                  .collectingAndUsingPersonalData
                  .trackingTechnologiesAndCookies.cookiesInformation),function(cookieInfo){return _c('li',{key:cookieInfo.type,staticClass:"mx-5"},[_c('span',[_vm._v(_vm._s(cookieInfo.type))]),_vm._v(": "+_vm._s(cookieInfo.purpose)+" ")])}),0),_c('ul',{staticStyle:{"list-style-type":"disc"}},_vm._l((_vm.privacyPolicy
                  .collectingAndUsingPersonalData.sharingPersonalInformation),function(situation){return _c('li',{key:situation,staticClass:"mx-5"},[_c('span',[_vm._v(_vm._s(situation))])])}),0),_c('p',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.privacyPolicy.retentionAndTransferOfPersonalData.retention)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.privacyPolicy.retentionAndTransferOfPersonalData.transfer)+" ")]),_c('h4',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.privacyPolicy.deletePersonalData.rights)+" ")]),_c('ul',{staticStyle:{"list-style-type":"disc"}},_vm._l((_vm.privacyPolicy.deletePersonalData.process),function(process){return _c('li',{key:process,staticClass:"mx-5"},[_vm._v(" "+_vm._s(process)+" ")])}),0),_c('p',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.privacyPolicy.securityOfPersonalData))]),_c('p',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.privacyPolicy.childrensPrivacy))]),_c('p',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.privacyPolicy.linksToOtherWebsites))]),_c('p',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.privacyPolicy.changesToPrivacyPolicy))]),_c('p',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.privacyPolicy.contactUs))])])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.articleDetails)?_c('div',{staticClass:"page-container"},[_c('v-container',{staticClass:"py-3 pb-10",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align-content":"stretch"}},[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"auto"}},[_c('img',{staticClass:"my-auto",attrs:{"src":require("../assets/PlatformLogo-01.svg"),"width":"100","height":"auto"}}),(
                _vm.language == 'en' &&
                _vm.articleDetails.category_name_english != null
              )?_c('span',{staticClass:"white--text px-4 dark-panel-1 py-1 fa-12"},[_vm._v(_vm._s(_vm.articleDetails.category_name_english))]):_c('span',{staticClass:"white--text px-4 dark-panel-1 py-1 fa-12"},[_vm._v(_vm._s(_vm.articleDetails.category_name_arabic))])])],1),_c('v-card',{staticClass:"main-card px-0",attrs:{"elevation":"0"}},[_c('v-row',_vm._l((_vm.articleDetails.images),function(image,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"image-container"},[_c('v-img',{staticClass:"mt-2 width-full",attrs:{"src":_vm.articleDetails.images && _vm.articleDetails.images.length > 0
                      ? image.image_path
                      : require('@/assets/No-Image-Placeholder.png')}},[_c('div',{staticClass:"gradient-overlay"},[_c('v-card-title',{staticClass:"pb-0 pt-2 px-2"},[(image.type == 'approved')?_c('span',{staticClass:"approved-tag white--text px-4 py-2 fa-18 d-block"},[_c('v-icon',{staticClass:"white--text",attrs:{"dark":""}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" "+_vm._s(_vm.json.postStatus.original))],1):_c('span',{staticClass:"fake-tag white--text px-5 py-2 fa-18 d-block"},[_c('v-icon',{staticClass:"white--text",attrs:{"dark":""}},[_vm._v("mdi-hand-back-left")]),_vm._v(" "+_vm._s(_vm.json.postStatus.fake))],1)])],1)])],1)])}),1),_c('v-card-title',{staticClass:"px-0"},[(_vm.language == 'en' && _vm.articleDetails.title_english != null)?_c('v-card-title',{staticClass:"dark--text fa-16 text-truncate pb-0 pt-1 px-0"},[_vm._v(_vm._s(_vm.articleDetails.title_english))]):_c('v-card-title',{staticClass:"dark--text fa-16 text-truncate pb-0 pt-1 px-0"},[_vm._v(_vm._s(_vm.articleDetails.title_arabic))])],1),_c('v-row',{staticClass:"pb-1 mt-2"},[_c('v-col',{staticClass:"align-self-end fa-10 py-0",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"white--dark mx-2"},[_vm._v("mdi-calendar-account-outline")]),_c('span',{staticClass:"white--dark"},[_vm._v(_vm._s(_vm.formatDate(_vm.articleDetails.created_at)))])],1),_c('v-col',{staticClass:"align-self-end fa-12 py-0",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"white--dark mx-2"},[_vm._v("mdi-eye-outline")]),_c('span',{staticClass:"white--dark"},[_vm._v(_vm._s(_vm.articleDetails.views_count))])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[(
                    _vm.language == 'en' && _vm.articleDetails.content_english != null
                  )?_c('div',{staticClass:"white pa-3"},[_c('span',{staticClass:"px-4 py-1 fa-14 lg:fa-20 justified"},[_vm._v(_vm._s(_vm.articleDetails.content_english))])]):_c('div',{staticClass:"white pa-3"},[_c('span',{staticClass:"px-4 py-1 fa-14 lg:fa-20 justified"},[_vm._v(_vm._s(_vm.articleDetails.content_arabic))])])])],1)],1)],1)],1),(_vm.allRelatedArticles != '')?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-col',{attrs:{"cols":"12 pb-8 px-0"}},[_c('span',{staticClass:"fa-20 mb-3"},[_vm._v(" "+_vm._s(_vm.json.articleDetails.relatedArticles)+" ")])]),_c('v-row',_vm._l((_vm.allRelatedArticles.slice(0, 6)),function(item,index){return _c('v-col',{key:index,staticClass:"mb-0 px-2",attrs:{"cols":"12","md":"4","lg":"4"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('v-img',{staticClass:"mx-auto main-item-image clickable",attrs:{"src":item.images && item.images.length > 0
                      ? item.images[0].image_path
                      : require('@/assets/No-Image-Placeholder.png'),"width":"100%","height":"auto"},on:{"click":function($event){return _vm.postDetails(item)}}},[_c('div',{staticClass:"gradient-overlay"})])],1),_c('v-col',{staticClass:"pt-0 my-0",attrs:{"cols":"8"}},[(_vm.language == 'en')?_c('span',{staticClass:"white--text px-4 dark-panel-2 py-1 fa-12"},[_vm._v(_vm._s(item.category_name_english))]):_c('span',{staticClass:"white--text px-4 dark-panel-2 py-1 fa-12"},[_vm._v(_vm._s(item.category_name_arabic))]),(_vm.language == 'en' && item.title_english != '')?_c('v-card-title',{staticClass:"dark--text fa-16 text-truncate pb-0 pt-1 px-0"},[_vm._v(_vm._s(item.title_english))]):_c('v-card-title',{staticClass:"dark--text fa-16 text-truncate pb-0 pt-1 px-0"},[_vm._v(_vm._s(item.title_arabic))]),_c('v-card-text',{staticClass:"align-self-end fa-10 py-0 px-0",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"white--dark",attrs:{"small":""}},[_vm._v("mdi-calendar-account-outline")]),_c('span',{staticClass:"white--dark fa-10 mx-2"},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])],1),_c('v-card-text',{staticClass:"align-self-end fa-12 py-0 px-0",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"white--dark",attrs:{"small":""}},[_vm._v("mdi-eye-outline")]),_c('span',{staticClass:"white--dark mx-2"},[_vm._v(_vm._s(item.views_count))])],1)],1)],1)],1)}),1)],1):_vm._e()],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading,"dir":"ltr"}},[_c('v-progress-circular',{staticClass:"mx-2",attrs:{"indeterminate":"","color":"light"}}),_c('span',{staticClass:"fa-24"},[_vm._v(" Loading data ...")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="white">
    <div class="page-container">
      <v-container fluid class="py-5">
        <v-row align="center">
          <v-col cols="12" md="auto" class="d-flex align-center">
            <img
              src="../../assets/PlatformLogo-01.svg"
              class="my-auto"
              width="100"
              height="auto"
            />
            <span class="fa-20 ml-2"> {{ $t("section6.title2") }} </span>
          </v-col>
        </v-row>

        <v-row align-content="stretch">
          <v-col
            v-for="(institute, index) in institutesNet"
            :key="index"
            cols="6"
            md="2"
            class="text-center d-flex align-center"
          >
            <div class="institute-card text-center mx-auto">
              <!-- Logo (assuming institute.logo is the path to the image) -->

              <div class="circular-image-container mx-auto">
                <!-- Logo -->
                <img
                  :src="require(`../../assets/imgs/logos/${institute.logo}`)"
                  class="logo"
                />
              </div>

              <!-- Name of the institute -->

              <div class="institute-name small-text text-center mt-2">
                {{ institute.name }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const lang = localStorage.getItem("lang") || "ar";
    return {
      institutesNet: [],
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
      language: lang,
    };
  },
  mounted() {
    // Load JSON data using an HTTP request or import statement
    if (this.language == "en") {
      import("../../assets/data/organizations_eng.json")
        .then((response) => response.default)
        .then((data) => {
          // Assuming the JSON file contains an array of institutes
          this.institutesNet = data;
        })
        .catch((error) => {
          console.error("Error loading JSON file:", error);
        });
    } else {
      import("../../assets/data/organizations.json")
        .then((response) => response.default)
        .then((data) => {
          // Assuming the JSON file contains an array of institutes
          this.institutesNet = data;
        })
        .catch((error) => {
          console.error("Error loading JSON file:", error);
        });
    }
  },
};
</script>
<style scoped>
.circular-image-container {
  width: 100px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  overflow: hidden;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid grey; /* Add a grey border around the circular image */
  filter: grayscale(100%); /* Apply grayscale filter by default */
  transition: 0.3s;
}

.circular-image-container:hover {
  filter: grayscale(40%); /* Remove the grayscale filter on hover */
}

.logo {
  width: 80%; /* Adjust the width as needed */
  height: auto;
  border-radius: 50%;
  display: block; /* Ensures the image is centered within the circular container */
  margin: auto; /* Center the image horizontally */
}
</style>

<template>
  <div class="user-dashboard" :dir="direction">
    <AppBar />
    <v-main>
      <v-container fluid class="px-0 pt-0">
        <v-col cols="12" class="px-0 pt-0">
          <router-view />
        </v-col>
      </v-container>
    </v-main>
    <AppFooter />
  </div>
</template>

<script>
import AppBar from "../components/AppBar";
import AppFooter from "../components/AppFooter";

export default {
  name: "UserDashboard",
  components: {
    AppBar,
    AppFooter,
  },
  data() {
    const lang = localStorage.getItem("lang") || "ar";

    return {
      direction: lang == "ar" ? "rtl" : "ltr",
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
    };
  },
};
</script>
<style lang="scss" scopped>
body,
html {
  overflow: auto;
  height: 100vh;
}
.user-dashboard {
  display: block;
  width: 100%;
  height: 100%;
  float: left;
  background-color: #f1f5f9;
}
</style>

<template>
  <div class="page-container">
    <v-container fluid class="py-3 pb-10">
      <v-row align="center">
        <v-col cols="12" md="auto" class="d-flex align-center">
          <img
            src="../../assets/PlatformLogo-01.svg"
            class="my-auto"
            width="100"
            height="auto"
          />
          <span class="fa-20 ml-2"
            >{{ legalSection.page_title }} | {{ legalSection.institute }}</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet class="pa-6">
            <!-- Loop through each section -->
            <div
              v-for="(section, sectionKey) in legalSection"
              :key="sectionKey"
            >
              <p class="font-weight-bold fa-18 text-justify">
                {{ section.title }}
              </p>
              <!-- Loop through each law in the section -->
              <div
                v-for="(law, index) in section.laws"
                :key="index"
                class="law-item text-justify"
              >
                <!-- Law Title -->
                <p class="font-weight-bold">{{ law.title }}</p>
                <!-- Law Description -->
                <p>{{ law.description }}</p>
              </div>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    const lang = localStorage.getItem("lang") || "ar";
    return {
      json: lang === "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
      selectedLanguage: lang,
      isActive: false,
      drawer: false,
      tab: null,
      legalSection: null,
    };
  },
  created() {
    // Assign menus from json
    this.legalSection = this.json.legalSection;
  },
};
</script>

<style scoped>
/* Add any additional styles here */
.font-weight-bold {
  font-weight: bold;
}
.text-justify {
  text-align: justify;
}
.law-item {
  margin-bottom: 1rem; /* Adjust spacing between laws */
}
</style>

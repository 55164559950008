<template>
  <div :dir="direction">
    <v-dialog v-model="showModal" fullscreen>
      <v-card class="search-modal" color="white" :dir="direction">
        <div align="center" class="grey-bar" elevation="0">
          <v-col cols="12" md="auto" class="d-flex align-center pb-0">
            <img
              src="../assets/PlatformLogo-01.svg"
              class="my-auto"
              width="100"
              height="auto"
            />
            <span class="ink-tab">
              {{ $t("title") }} | {{ $t("subtitle") }}</span
            >
          </v-col>
          <v-spacer></v-spacer>
          <!-- Close button -->
          <v-btn icon @click="$emit('close')" class="close-button my-auto">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="page-container">
          <div align="center" class="px-4 py-4">
            <v-col cols="12" md="8">
              <!-- Styled text field -->
              <v-text-field
                v-model="searchQuery"
                label="Search"
                outlined
                clearable
                hide-details
                dense
                placeholder="Search..."
              ></v-text-field>
            </v-col>
          </div>
          <v-row class="mt-4">
            <v-col
              v-for="(item, index) in allArticles"
              :key="index"
              cols="12"
              md="12"
              lg="6"
              class="mb-0 px-2"
            >
              <v-row>
                <v-col cols="4" class="pt-0">
                  <v-img
                    @click="postDetails(item)"
                    :src="
                      item.images && item.images.length > 0
                        ? item.images[0].thumbnail_path
                        : require('@/assets/No-Image-Placeholder.png')
                    "
                    class="mx-auto main-item-image clickable"
                    width="auto%"
                    height="8em"
                    ><div class="gradient-overlay"></div
                  ></v-img>
                </v-col>
                <v-col cols="8" class="pt-0">
                  <span
                    v-if="language == 'en'"
                    class="white--text px-4 dark-panel-1 py-1 fa-12"
                    >{{ item.category_name_english }}</span
                  >

                  <span
                    v-else
                    class="white--text px-4 dark-panel-1 py-1 fa-12"
                    >{{ item.category_name_arabic }}</span
                  >

                  <v-card-title
                    v-if="language == 'en' && item.title_english != ''"
                    class="dark--text fa-16 text-truncate pb-0 pt-1 px-0"
                    >{{ item.title_english }}</v-card-title
                  >
                  <v-card-title
                    v-else
                    class="dark--text fa-16 text-truncate pb-0 pt-1 px-0"
                    >{{ item.title_arabic }}</v-card-title
                  >
                  <v-card-text class="align-self-end fa-10 py-0 px-0" cols="12">
                    <v-icon class="black--text" small
                      >mdi-calendar-account-outline</v-icon
                    >
                    <span class="white--dark fa-10 px-2">{{
                      formatDate(item.created_at)
                    }}</span>
                  </v-card-text>
                  <v-card-text class="align-self-end fa-12 py-0 px-0" cols="12">
                    <v-icon class="black--text" small>mdi-eye-outline</v-icon>
                    <span class="black--text px-2">{{ item.views_count }}</span>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <v-overlay :value="loading" dir="ltr">
        <v-progress-circular
          class="mx-2"
          indeterminate
          color="light"
        ></v-progress-circular>
        <span class="fa-24"> Loading data ...</span>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: function () {
    const lang = localStorage.getItem("lang") || "ar";

    return {
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
      loading: false,
      direction: lang == "ar" ? "rtl" : "ltr",
      language: lang,
      searchQuery: null,
      info: {
        page: 1,
        keyword: null,
        tag: null,
      },
    };
  },
  props: {
    showModal: Boolean,
  },
  watch: {
    currentGet(newVal, oldVal) {
      if (newVal != oldVal) {
        this.info.page = newVal;
        this.info.searcg = this.searchQuery;
        this.disablePagination = true;
        this.fetchArticles(this.info)
          .then(() => {
            this.disablePagination = false;
          })
          .catch(() => {
            this.disablePagination = false;
          });
      }
    },

    // Watch for changes in searchQuery
    searchQuery(newVal, oldVal) {
      // If the new value is different from the old one
      if (newVal !== oldVal) {
        // Clear previous timeout (if any) to prevent multiple requests
        clearTimeout(this.searchTimeout);
        // Set a new timeout to execute the search after 1 second of inactivity
        this.searchTimeout = setTimeout(() => {
          // Call fetchArticles function with the updated keyword
          this.info.search = newVal;
          this.loading = true;
          this.fetchArticles(this.info)
            .then(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }, 1000);
      }
    },
  },
  computed: {
    ...mapGetters(["allArticles", "getRole", "getUser"]),

    currentGet: {
      get() {
        return this.$store.getters.getArticleCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setArticleCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getArticleLastPage;
      },
    },
  },
  created() {
    this.disablePagination = true;
    this.fetchArticles(this.info)
      .then(() => {
        this.disablePagination = false;
      })
      .catch(() => {
        this.disablePagination = false;
      });
  },

  methods: {
    ...mapActions(["fetchArticles"]),

    showArticleDetails(item) {
      this.articleData = item;
      this.EditArticleDialog = true;
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    postDetails(item) {
      this.$router.push({ name: "PostDetails", params: { id: item.id } });
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.search-modal {
  background-color: white;
}
.center-aligned {
  align-items: center;
}
.search-input {
  display: flex;
}

.search-input input {
  background-color: transparent;
  border: 1px solid black;
  color: black;
}

.close-button {
  position: absolute;
  top: 20px;
  left: 10px; /* Adjusted to float to the left */
  z-index: 999; /* Ensure it appears above the modal */
}
</style>

<template>
  <div class="">
    <div class="page-container">
      <v-container fluid class="py-3 pb-10">
        <v-row align="center">
          <v-col cols="12" md="auto" class="d-flex align-center">
            <img
              src="../../assets/PlatformLogo-01.svg"
              class="my-auto"
              width="100"
              height="auto"
            />
            <span class="fa-20 ml-2"> {{ privacyPolicy.title }} </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-sheet class="pa-6">
              <!-- Privacy Policy Heading -->

              <!-- Last Updated Information -->
              <p>{{ privacyPolicy.lastUpdated }}</p>

              <!-- Introduction -->
              <p>
                {{ privacyPolicy.description }}
              </p>

              <!-- Service Usage Agreement -->
              <p>
                <v-icon>mdi-information-outline</v-icon>
                {{ privacyPolicy.usageAgreement }}
              </p>

              <!-- Interpretation and Definitions Heading -->
              <h3>
                {{ privacyPolicy.interpretationAndDefinitions.interpretation }}
              </h3>

              <!-- Definitions Section -->
              <h3 class="mt-5">
                {{
                  privacyPolicy.interpretationAndDefinitions.definitions.title
                }}
              </h3>
              <ul style="list-style-type: disc">
                <li
                  class="mx-5"
                  v-for="(definition, definitionData) in privacyPolicy
                    .interpretationAndDefinitions.definitions.data"
                  :key="definitionData"
                >
                  <strong>{{ definition.title }} : </strong>
                  {{ definition.text }}
                </li>
              </ul>

              <!-- Collecting and Using Your Personal Data Heading -->

              <p class="mt-5 fa-20">
                <strong
                  >{{
                    privacyPolicy.collectingAndUsingPersonalData
                      .typesOfDataCollected.title
                  }}
                </strong>
                |
                <span class="fa-16">
                  <v-icon>mdi-information-outline</v-icon>
                  {{
                    privacyPolicy.collectingAndUsingPersonalData
                      .typesOfDataCollected.usageData
                  }}
                </span>
              </p>

              <!-- Personal Data Section -->
              <ul style="list-style-type: disc">
                <li
                  class="mx-5"
                  v-for="dataPoint in privacyPolicy
                    .collectingAndUsingPersonalData.typesOfDataCollected
                    .personalData"
                  :key="dataPoint"
                >
                  {{ dataPoint }}
                </li>
              </ul>

              <!-- Tracking Technologies and Cookies Heading -->
              <h3 class="mt-5">
                {{
                  privacyPolicy.collectingAndUsingPersonalData
                    .trackingTechnologiesAndCookies.description
                }}
              </h3>

              <ul style="list-style-type: disc">
                <!-- List of Tracking Technologies -->
                <li
                  class="mx-5"
                  v-for="technology in privacyPolicy
                    .collectingAndUsingPersonalData
                    .trackingTechnologiesAndCookies.technologiesUsed"
                  :key="technology"
                >
                  {{ technology }}
                </li>
              </ul>
              <ul style="list-style-type: disc">
                <!-- List of Cookies Information -->
                <li
                  class="mx-5"
                  v-for="cookieInfo in privacyPolicy
                    .collectingAndUsingPersonalData
                    .trackingTechnologiesAndCookies.cookiesInformation"
                  :key="cookieInfo.type"
                >
                  <span>{{ cookieInfo.type }}</span
                  >: {{ cookieInfo.purpose }}
                </li>
              </ul>
              <ul style="list-style-type: disc">
                <!-- List of Cookies Information using v-for loop -->
                <li
                  class="mx-5"
                  v-for="cookieInfo in privacyPolicy
                    .collectingAndUsingPersonalData
                    .trackingTechnologiesAndCookies.cookiesInformation"
                  :key="cookieInfo.type"
                >
                  <span>{{ cookieInfo.type }}</span
                  >: {{ cookieInfo.purpose }}
                </li>
              </ul>

              <ul style="list-style-type: disc">
                <!-- List of Situations -->
                <li
                  class="mx-5"
                  v-for="situation in privacyPolicy
                    .collectingAndUsingPersonalData.sharingPersonalInformation"
                  :key="situation"
                >
                  <span>{{ situation }}</span>
                </li>
              </ul>

              <!-- Retention of Your Personal Data Heading -->

              <p class="mt-5">
                {{ privacyPolicy.retentionAndTransferOfPersonalData.retention }}
              </p>

              <p>
                {{ privacyPolicy.retentionAndTransferOfPersonalData.transfer }}
              </p>

              <!-- Delete Your Personal Data Heading -->
              <h4 class="mt-5">
                {{ privacyPolicy.deletePersonalData.rights }}
              </h4>
              <ul style="list-style-type: disc">
                <!-- List of Processes -->
                <li
                  class="mx-5"
                  v-for="process in privacyPolicy.deletePersonalData.process"
                  :key="process"
                >
                  {{ process }}
                </li>
              </ul>

              <!-- Security of Your Personal Data Heading -->
              <p class="mt-5">{{ privacyPolicy.securityOfPersonalData }}</p>

              <!-- Children's Privacy Heading -->
              <p class="mt-5">{{ privacyPolicy.childrensPrivacy }}</p>

              <!-- Links to Other Websites Heading -->
              <p class="mt-5">{{ privacyPolicy.linksToOtherWebsites }}</p>

              <!-- Changes to this Privacy Policy Heading -->
              <p class="mt-5">{{ privacyPolicy.changesToPrivacyPolicy }}</p>

              <!-- Contact Us Heading -->
              <p class="mt-5">{{ privacyPolicy.contactUs }}</p>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const lang = localStorage.getItem("lang") || "ar";
    return {
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
      selectedLanguage: lang,
      isActive: false,
      drawer: false,
      tab: null,
      privacyPolicy: null,
    };
  },
  computed: {},
  created() {
    //assign menus from json
    this.privacyPolicy = this.json.privacy_policy;
  },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./scss/main.scss";
import i18n from "./i18n";
import VueCookies from "vue-cookies";

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(VueCookies, { expire: "7d" });
// Create a global variable for direction
const lang = localStorage.getItem("lang") || "ar";
Vue.prototype.$direction = lang == "ar" ? "rtl" : "ltr";

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

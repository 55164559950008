<template>
  <div class="">
    <div class="page-container">
      <v-container fluid class="py-10">
        <v-row align-content="stretch">
          <v-col cols="12" md="6" lg="6">
            <v-row align="center">
              <v-col cols="12" md="auto" class="d-flex align-center">
                <img
                  src="../../assets/PlatformLogo-01.svg"
                  class="my-auto"
                  width="100"
                  height="auto"
                />
                <span class="fa-20 ml-2"> {{ json.section6.title }} </span>
              </v-col>
            </v-row>

            <ul>
              <li v-for="(item, index) in objectives" :key="index">
                <v-row>
                  <v-col cols="1">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-col>
                  <v-col cols="11">
                    <span>{{ item.text }}</span>
                  </v-col>
                </v-row>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="6">
                <v-card elevation="1" class="mx-auto py-3">
                  <v-card-text class="text-center header-text pt-3 fa-22">
                    {{ cabiNet.title }}
                  </v-card-text>

                  <v-img
                    class="text-white grayscale-hover px-3"
                    width="100%"
                    height="auto"
                    src="../../assets/imgs/cabi.png"
                    contain
                  ></v-img>

                  <p class="fa-14 text-center px-3 pt-1 header-text">
                    {{ cabiNet.description }}
                  </p>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card elevation="1" class="mx-auto py-3">
                  <v-card-text class="text-center header-text fa-22">
                    {{ cmcData.title }}
                  </v-card-text>
                  <v-img
                    class="text-white grayscale-hover px-3"
                    width="100%"
                    height="auto"
                    src="../../assets/imgs/cmclogo.png"
                    contain
                  ></v-img>
                  <p class="fa-14 text-center px-3 header-text">
                    {{ cmcData.description }}
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    const lang = localStorage.getItem("lang") || "ar";

    return {
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
      language: lang,
      objectives: null,
      cabiNet: null,
      cmcData: null,
    };
  },
  created() {
    //assign menus from json
    this.objectives = this.json.section6.objectives;
    this.cabiNet = this.json.section6.cabiNet;
    this.cmcData = this.json.section6.cmcData;
  },
};
</script>
<style scoped>
.main-card {
  min-height: 100%;
  position: relative;
  overflow: hidden;
  background-color: transparent;
}

.main-card .main-item-image {
  width: 100%;
  height: auto;
  display: block;
  transition: filter 0.3s;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 100, 0.8),
    rgba(0, 0, 100, 0.4)
  ) !important;
}

.main-card .main-item-image:hover {
  filter: brightness(1.2);
}

.gradient-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 200, 0.2),
    rgba(0, 0, 200, 0.2)
  );
  z-index: 1;
  pointer-events: none; /* Ensure the overlay doesn't block interactions with the image */
}

.gradient-overlay-2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 120, 0.2)
  );
  z-index: 1;
  pointer-events: none; /* Ensure the overlay doesn't block interactions with the image */
}

.blog-card {
  min-height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
}

.blog-card .main-item-image {
  width: 100%;
  height: auto;
  display: block;
  transition: filter 0.4s !important;
}

.blog-card:hover .main-item-image {
  filter: brightness(1.2);
}

.white--text {
  color: white !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block; /* Necessary to apply text-overflow in flex container */
}

.text-truncate-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.grayscale-hover {
  filter: grayscale(100%);
  transition: filter 0.5s !important;
}

.custom-card {
  text-align: center;
}

.custom-image {
  margin: 0 auto;
}
</style>

import { render, staticRenderFns } from "./Section6.vue?vue&type=template&id=15611235&scoped=true"
import script from "./Section6.vue?vue&type=script&lang=js"
export * from "./Section6.vue?vue&type=script&lang=js"
import style0 from "./Section6.vue?vue&type=style&index=0&id=15611235&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15611235",
  null
  
)

export default component.exports
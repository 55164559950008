<template>
  <v-footer class="white darken-2">
    <v-container class="py-16">
      <v-row>
        <v-col cols="12" md="4">
          <v-row>
            <v-col class="mr-2">
              <v-img
                src="../assets/PlatformLogo-01.svg"
                alt="Repair Warehouse Logo"
                width="100%"
                height="auto"
              ></v-img>
            </v-col>
            <v-col>
              <a href="/" class="no-underline footer-link logo-text fa-18">
                {{ title }}
              </a>
              <p class="mt-4 max-w-xs fa-12">
                {{ subtitle }}
              </p>
              <v-row class="mt-8">
                <v-col
                  v-for="(socialButton, index) in socialButtons"
                  :key="index"
                  cols="2 mx-1"
                >
                  <v-btn
                    icon
                    :href="socialButton.href"
                    target="_blank"
                    class="transition hover:opacity-75"
                  >
                    <v-icon>{{ socialButton.icon }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="6" md="3">
              <v-subheader class="font-weight-medium text-gray-900">{{
                categories.name
              }}</v-subheader>
              <v-list dense>
                <v-list-item
                  v-for="(link, index) in categories.list.flat()"
                  :key="index"
                >
                  <v-list-item-content>
                    <a
                      :href="link.url"
                      class="transition hover:opacity-75 footer-link"
                      >{{ link.name }}</a
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="6" md="3">
              <v-subheader class="font-weight-medium text-gray-900">
                {{ research.name }}
              </v-subheader>
              <v-list dense>
                <v-list-item
                  v-for="(link, index) in research.list.flat()"
                  :key="index"
                >
                  <v-list-item-content>
                    <a
                      :href="link.url"
                      class="transition hover:opacity-75 footer-link"
                      >{{ link.name }}</a
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="6" md="3">
              <v-subheader class="font-weight-medium text-gray-900">
                {{ policy.name }}</v-subheader
              >
              <v-list dense>
                <v-list-item
                  v-for="(link, index) in policy.list.flat()"
                  :key="index"
                >
                  <v-list-item-content>
                    <a
                      :href="link.url"
                      class="transition hover:opacity-75 footer-link"
                      >{{ link.name }}</a
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="6" md="3">
              <v-subheader class="font-weight-medium text-gray-900">
                {{ about.name }}
              </v-subheader>
              <v-list dense>
                <v-list-item
                  v-for="(link, index) in about.list.flat()"
                  :key="index"
                >
                  <v-list-item-content>
                    <a
                      :href="link.url"
                      class="transition hover:opacity-75 footer-link"
                      >{{ link.name }}</a
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <span class="text-xs py-0 d-block text-center">
            <span class="fa-22">&copy;</span> {{ new Date().getFullYear() }} -
            {{ copyright }}
          </span>
          <span class="py-0 fa-12 d-block text-center grey--text">
            Code, Software Development, and Platform Deployment, are made by
            &copy; Department of Automation / the Communication and Media
            Commission of Iraq (CMC)
          </span>
          <span class="py-0 fa-12 d-block text-center grey--text">
            The platform resources are protected by the government of Iraq. No
            part of this resources can be used, distributed, or violated under
            any circumstances without the permission of the author
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    const lang = localStorage.getItem("lang") || "ar";
    return {
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
      selectedLanguage: lang,
      isActive: false,
      drawer: false,
      tab: null,
      menus: [],

      socialButtons: [
        { icon: "mdi-facebook", href: "https://www.facebook.com/your-page" },

        { icon: "mdi-whatsapp", href: "https://whatsapp" },
      ],
      categories: {},
      research: {},
      policy: {},
      about: {},
      title: null,
      subtitle: null,
      copyright: null,
    };
  },
  computed: {},
  created() {
    //assign menus from json
    let footer = this.json.footer;
    //assign for corresponding object
    this.title = footer.title;
    this.subtitle = footer.subtitle;
    this.categories = footer.categories;
    this.research = footer.research;
    this.policy = footer.policy;
    this.about = footer.about;
    this.copyright = footer.copyright;
  },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>

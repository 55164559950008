<template>
  <v-container v-if="!accepted" class="cookie-policy" fluid :dir="direction">
    <v-row>
      <v-col md="10">
        <div class="cookie-content">
          <p>
            {{ $t("cookies") }}
          </p>
        </div>
      </v-col>
      <v-col md="2">
        <v-btn @click="acceptCookie" class="black white--text">OK</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    const lang = localStorage.getItem("lang") || "ar";

    return {
      loading: false,
      direction: lang == "ar" ? "rtl" : "ltr",
      language: lang,

      accepted: false,
    };
  },
  mounted() {
    // Check local storage to see if the user has already accepted the cookie policy
    const hasAccepted = localStorage.getItem("cookiePolicyAccepted");
    this.accepted = hasAccepted === "true";
  },
  methods: {
    acceptCookie() {
      // Set the flag in local storage
      localStorage.setItem("cookiePolicyAccepted", "true");

      // Update the component state
      this.accepted = true;
    },
  },
};
</script>

<style scoped>
.cookie-policy {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff; /* White background */
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow at the bottom */
  padding: 10px;
  text-align: center; /* Center the content */
  z-index: 1000; /* Set a high z-index value to appear above other elements */
}

.cookie-content {
  max-width: 100%; /* Adjust the max-width as needed */
}

.accept-btn {
  background-color: #000; /* Black button background */
  color: #fff; /* White button text */
  width: 100%; /* Make the button fill its container */
}
</style>

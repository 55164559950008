<template>
  <div class="">
    <div class="page-container py-0 fill-height">
      <v-container fluid class="py-3 pb-10">
        <v-row>
          <v-col cols="12" md="auto" class="d-flex align-center px-0">
            <img
              src="../assets/PlatformLogo-01.svg"
              class="my-auto"
              width="100"
              height="auto"
            />
            <span class="fa-20 ml-2">{{ tag_name }} </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(item, index) in allCategoryArticles"
            :key="index"
            cols="12"
            md="6"
            lg="6"
            class="mb-0 px-2"
          >
            <v-row>
              <v-col cols="4" class="pt-0">
                <v-img
                  @click="postDetails(item)"
                  :src="
                    item.images && item.images.length > 0
                      ? item.images[0].image_path
                      : require('@/assets/No-Image-Placeholder.png')
                  "
                  class="main-item-image clickable"
                  contain
                  width="100%"
                  height="100px"
                  ><div class="gradient-overlay"></div
                ></v-img>
              </v-col>
              <v-col cols="8" class="pt-1">
                <span
                  v-if="language == 'en'"
                  class="white--text px-4 dark-panel-1 py-1 fa-12"
                  >{{ item.category_name_english }}</span
                >

                <span v-else class="white--text px-4 dark-panel-1 py-1 fa-12">{{
                  item.category_name_arabic
                }}</span>
                <v-card-title
                  v-if="language == 'en' && item.category_name_english != ''"
                  class="dark--text fa-16 text-truncate pb-0 pt-1 px-0"
                  >{{ item.category_name_english }}</v-card-title
                >
                <v-card-title
                  v-else
                  class="dark--text fa-16 text-truncate pb-0 pt-1 px-0"
                  >{{ item.category_name_arabic }}</v-card-title
                >
                <v-card-text class="align-self-end fa-10 py-0 px-0" cols="12">
                  <v-icon class="black--text me-2" small
                    >mdi-calendar-account-outline</v-icon
                  >
                  <span class="white--dark fa-10">{{
                    formatDate(item.created_at)
                  }}</span>
                </v-card-text>
                <v-card-text class="align-self-end fa-12 py-0 px-0" cols="12">
                  <v-icon class="black--text me-2" small
                    >mdi-eye-outline</v-icon
                  >
                  <span class="black--text">{{ item.views_count }}</span>
                </v-card-text>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-overlay :value="loading" dir="ltr">
      <v-progress-circular
        class="mx-2"
        indeterminate
        color="light"
      ></v-progress-circular>
      <span class="fa-24"> Loading data ...</span>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    const lang = localStorage.getItem("lang") || "ar";

    return {
      loading: false,
      tag_name: "",
      info: {
        page: 1,
        keyword: null,
        tag: null,
      },
      category: null,
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
      language: lang,
      menus: null,
      menus2: null,
    };
  },
  computed: {
    ...mapGetters(["allCategoryArticles", "getRole", "getUser"]),
  },
  watch: {
    "$route.params.category": {
      handler(newCategory) {
        this.info.tag = newCategory;
        this.getTagName();
        this.fetchArticles();
      },
      immediate: true,
    },
  },
  created() {
    this.info.tag = this.$route.params.category;
    this.getTagName();
    this.fetchArticles();
  },
  methods: {
    ...mapActions(["fetchArticlesByCategory"]),

    fetchArticles() {
      this.loading = true;
      this.fetchArticlesByCategory(this.info)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          console.error("An error occurred while fetching articles:", error);
          this.loading = false;
        });
    },

    showArticleDetails(item) {
      this.articleData = item;
      this.EditArticleDialog = true;
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },

    postDetails(item) {
      this.$router.push({ name: "PostDetails", params: { id: item.id } });
    },

    getTagName() {
      this.menus = this.json.menus[1].children;
      this.menus2 = this.json.menus[2].children;
      switch (this.info.tag) {
        case "documents":
          this.tag_name = this.menus[0].title;
          break;
        case "news":
          this.tag_name = this.menus[1].title;
          break;
        case "data":
          this.tag_name = this.menus[2].title;
          break;
        case "pictures":
          this.tag_name = this.menus[3].title;
          break;
        case "videos":
          this.tag_name = this.menus[4].title;
          break;
        case "articles":
          this.tag_name = this.menus2[0].title;
          break;
        case "research":
          this.tag_name = this.menus2[1].title;
          break;
        // Add more cases as needed for other tag values
        default:
          this.tag_name = "Other";
          break;
      }
    },
  },
};
</script>

<style scoped>
.main-card {
  min-height: 100%;
  position: relative;
  overflow: hidden;
  background-color: transparent;
}

.main-card .main-item-image {
  width: 100%;
  height: auto;
  display: block;
  transition: filter 0.3s;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 100, 0.8),
    rgba(0, 0, 100, 0.4)
  ) !important;
}

.main-card .main-item-image:hover {
  filter: brightness(1.2);
}

.gradient-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 200, 0.2),
    rgba(0, 0, 200, 0.2)
  );
  z-index: 1;
  pointer-events: none; /* Ensure the overlay doesn't block interactions with the image */
}

.gradient-overlay-2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 120, 0.2)
  );
  z-index: 1;
  pointer-events: none; /* Ensure the overlay doesn't block interactions with the image */
}

.blog-card {
  min-height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
}

.blog-card .main-item-image {
  width: 100%;
  height: auto;
  display: block;
  transition: filter 0.4s !important;
}

.blog-card:hover .main-item-image {
  filter: brightness(1.2);
}

.white--text {
  color: white !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block; /* Necessary to apply text-overflow in flex container */
}

.text-truncate-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

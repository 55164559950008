<template>
  <v-app>
    <loading :isLoading="loading"></loading>
    <CookiePolicy />

    <router-view />
  </v-app>
</template>

<script>
import Loading from "./components/LoadingScreen.vue";
import CookiePolicy from "./components/CookiePolicy.vue";

export default {
  name: "App",
  components: {
    Loading,
    CookiePolicy,
  },
  data() {
    return {
      loading: true, // Set to true initially
    };
  },
  mounted() {
    // Simulate the content loading (you can replace this with actual loading logic)
    setTimeout(() => {
      this.loading = false; // Set to false when content is loaded
    }, 2000); // Adjust the timeout based on your loading time
  },
};
</script>

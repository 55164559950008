import axios from "@/axios";

const state = {
  currentArticle: {},
  articles: {
    data: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
  },
  relatedArticles: {
    data: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
  },

  categoryArticles: {
    data: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
  },

  governmentArticles: {
    data: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
  },

  documentsArticles: {
    data: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
  },

  newsArticles: {
    data: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
  },
  picturesArticles: {
    data: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
  },

  videoArticles: {
    data: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
  },

  researchArticles: {
    data: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
  },

  postArticles: {
    data: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
  },
};

const getters = {
  allArticles: (state) => state.articles.data,
  allGovernmentArticle: (state) => state.governmentArticles.data,
  allRelatedArticles: (state) => state.relatedArticles.data,
  allDocumentArticles: (state) => state.documentsArticles.data,
  allNewsArticles: (state) => state.newsArticles.data,
  allImagesArticles: (state) => state.picturesArticles.data,
  allVideosArticles: (state) => state.videoArticles.data,
  allResearchArticles: (state) => state.researchArticles.data,
  allPostArticles: (state) => state.postArticles.data,
  allCategoryArticles: (state) => state.categoryArticles.data,

  //get current article data
  articleDetails: (state) => state.currentArticle,

  getArticle: (state) => (id) => {
    return state.articles.data.find((articles) => articles.id === id);
  },
  getArticleProfile: (state) => state.article,
  getArticleCurrentPage: (state) =>
    state.articles.pagination ? state.articles.pagination.current_page : 1,
  getArticleLastPage: (state) =>
    state.articles.pagination ? state.articles.pagination.total_pages : 1,
};
const actions = {
  async fetchArticles({ commit }, info) {
    var page = info.page;
    var keyword = info.search;
    // define how many record are fetched per page
    var perPage = info.perPage ? info.perPage : 16;
    if (keyword != "" && keyword != null) {
      const response = await axios.get(
        "/public/articles?per_page=" +
          perPage +
          "&page=" +
          page +
          "&search=" +
          keyword
      );
      commit("SET_ARTICLE_DATA", response.data);
    } else {
      const response = await axios.get(
        "/public/articles?per_page=" + perPage + "&page=" + page
      );
      commit("SET_ARTICLE_DATA", response.data);
    }
  },

  async fetchGovernmentArticles({ commit }, info) {
    var page = info.page;
    //var keyword = info.keyword;
    var category = info.category;
    // define how many record are fetched per page
    var perPage = info.perPage ? info.perPage : 16;

    const response = await axios.get(
      "/public/articles?per_page=" +
        perPage +
        "&page=" +
        page +
        "&category_id=" +
        category
    );
    commit("SET_GOVERNMENT_ARTICLE", response.data);
  },

  async fetchRelatedCategories({ commit }, info) {
    var page = info.page;
    var category = info.category;
    var perPage = info.perPage ? info.perPage : 16;

    const response = await axios.get(
      "/public/articles?per_page=" +
        perPage +
        "&page=" +
        page +
        "&category_id=" +
        category
    );
    commit("SET_RELATED_ARTICLE", response.data);
  },

  async fetchArticlesByCategory({ commit }, info) {
    // Perform different API requests based on the tag value
    var page = info.page;
    // define how many record are fetched per page
    var perPage = info.perPage ? info.perPage : 16;
    // get the tag
    var tag = info.tag;
    // API Request
    const response = await axios.get(
      "/public/articles?per_page=" +
        perPage +
        "&page=" +
        page +
        "&search=" +
        tag
    );
    commit("SET_CATEGORY_ARTICLE_INFO", response.data);
  },

  async fetchArticlesByTag({ commit }, info) {
    // Perform different API requests based on the tag value
    var page = info.page;
    // define how many record are fetched per page
    var perPage = info.perPage ? info.perPage : 16;
    // get the tag
    var tag = info.tag;
    // API Request
    const response = await axios.get(
      "/public/articles?per_page=" +
        perPage +
        "&page=" +
        page +
        "&search=" +
        tag
    );

    switch (tag) {
      case "documents": {
        // Mutate document articles
        commit("SET_DOCUMENT_ARTICLES", response.data);
        break;
      }
      case "news": {
        // Mutate news articles
        commit("SET_NEWS_ARTICLES", response.data);
        break;
      }
      case "pictures": {
        // Mutate news articles
        commit("SET_IMAGES_ARTICLES", response.data);
        break;
      }
      case "videos": {
        // Mutate news articles
        commit("SET_VIDEOS_ARTICLES", response.data);
        break;
      }
      case "research": {
        // Mutate news articles
        commit("SET_RESEARCH_ARTICLES", response.data);
        break;
      }
      case "articles": {
        // Mutate news articles
        commit("SET_BLOG_ARTICLES", response.data);
        break;
      }
      // Add cases for other types of articles as needed
    }
  },

  // fetch limited article
  async fetchLimitedArticles({ commit }, limit) {
    const response = await axios.get(
      "/" + this.getters["getRole"] + "/articles?limit=" + limit
    );
    commit("SET_ARTICLE_DATA", response.data);
  },

  // fetch limited article
  async fetchArticleById({ commit }, id) {
    try {
      const response = await axios.get("/public/articles/" + id);
      if (response.status === 200) {
        commit("SET_CURRENT_ARTICLE_DATA", response.data);
      } else {
        throw new Error("Failed to fetch article");
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  // fetch limited article
  async fetchArticleBySlug({ commit }, slug) {
    try {
      const response = await axios.get(
        "/" + this.getters["getRole"] + "/article/" + slug
      );
      if (response.status === 200) {
        commit("SET_ARTICLE_PROFILE", response.data[0]);
      } else {
        throw new Error("Failed to fetch article");
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  //EDIT ARTICLES
  async editArticle({ commit }, info) {
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/articles/" + info.get("id"),
      info
    );
    if (response.status == 200)
      commit("EDIT_ARTICLE_DATA", response.data.article);
  },

  async fetchReportData({ commit }, info) {
    try {
      const response = await axios.get(
        "/" + this.getters["getRole"] + "/report",
        info
      );
      if (response.status == 200) commit("SET_EXPORT_DATA", response.data.data);
    } catch (error) {
      console.error(error);
      return null; // Return a default value or handle the error appropriately
    }
  },
  async addArticleFunc({ commit }, Article) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/articles",
      Article
    );
    if (response.status == 200) {
      commit("ADD_ARTICLE", response.data.article);
      return "success";
    } else return "failed";
  },

  async deleteArticle({ commit }, info) {
    const response = await axios.post(
      "/" + this.getters["getRole"] + "/computation/" + info.id,
      {
        _method: "delete",
      }
    );
    if (response.status == 200) commit("DELETE_Article", info);
    return response.data;
  },

  setArticleCurrentPage({ commit }, currentPage) {
    commit("SET_ARTICLE_CURRENT_PAGE", currentPage);
  },
};
const mutations = {
  SET_ARTICLE_DATA: (state, data) => {
    state.articles.data = data.data;
    state.articles.pagination = data.meta;
  },

  SET_CURRENT_ARTICLE_DATA: (state, data) => {
    state.currentArticle = data.data;
  },

  SET_GOVERNMENT_ARTICLE: (state, data) => {
    state.governmentArticles.data = data.data;
    state.governmentArticles.pagination = data.meta;
  },

  SET_RELATED_ARTICLE: (state, data) => {
    state.relatedArticles.data = data.data;
    state.relatedArticles.pagination = data.meta;
  },

  SET_CATEGORY_ARTICLE_INFO: (state, data) => {
    state.categoryArticles.data = data.data;
    state.categoryArticles.pagination = data.meta;
  },

  SET_DOCUMENT_ARTICLES: (state, data) => {
    state.documentsArticles.data = data.data;
    state.documentsArticles.pagination = data.meta;
  },

  SET_NEWS_ARTICLES: (state, data) => {
    state.newsArticles.data = data.data;
    state.newsArticles.pagination = data.meta;
  },

  SET_IMAGES_ARTICLES: (state, data) => {
    state.picturesArticles.data = data.data;
    state.picturesArticles.pagination = data.meta;
  },

  SET_VIDEOS_ARTICLES: (state, data) => {
    state.videoArticles.data = data.data;
    state.videoArticles.pagination = data.meta;
  },

  SET_RESEARCH_ARTICLES: (state, data) => {
    state.researchArticles.data = data.data;
    state.researchArticles.pagination = data.meta;
  },

  SET_BLOG_ARTICLES: (state, data) => {
    state.postArticles.data = data.data;
    state.postArticles.pagination = data.meta;
  },

  SET_ARTICLE_PROFILE: (state, data) => (state.article = data),

  SET_ArticleLIST: (state, data) => (state.articleList = data),
  //SET_EMPLOYEE_DATA: (state, data) => (state.employees = data),
  SET_EXPORT_DATA: (state, data) => (state.reportData = data),

  ADD_ARTICLE: (state, data) => state.articles.data.push(data),

  DELETE_Article: (state, data) => {
    const i = state.articles.data.map((item) => item.id).indexOf(data.id);
    state.Articles.data.splice(i, 1);
  },
  EDIT_ARTICLE_DATA: (state, data) => {
    const i = state.articles.data.map((item) => item.id).indexOf(data.id);
    state.articles.data[i].id = data.id;
    state.articles.data[i].title = data.title;
    state.articles.data[i].content = data.content;
    state.articles.data[i].approved = data.approved;
    state.articles.data[i].created_at = data.created_at;
    state.articles.data[i].category_name = data.category_name;
    state.articles.data[i].english = data.english;
    state.articles.data[i].images[0] = data.images[0];
  },
  EDIT_ARTICLE_VENDORS_DATA: (state, data) => {
    const i = state.articles.data.map((item) => item.id).indexOf(data.id);
    state.articles.data[i].id = data.id;

    state.articles.data[i].vendors = data.vendors;
  },

  SET_ARTICLE_CURRENT_PAGE: (state, data) =>
    (state.articles.pagination.current_page = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};

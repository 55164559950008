<template>
  <div
    class="dark-banner-2 white--text"
    v-if="
      (allImagesArticles && allImagesArticles.length > 0) ||
      (allVideosArticles && allVideosArticles.length > 0)
    "
  >
    <div class="page-container">
      <v-container fluid class="py-3 pb-10">
        <v-row align-content="stretch">
          <v-col cols="12" md="6" v-if="allImagesArticles != ''">
            <v-row align="center">
              <v-col cols="12" md="auto" class="d-flex align-center px-0">
                <img
                  src="../../assets/PlatformLogo-03.svg"
                  class="my-auto"
                  width="100"
                  height="auto"
                />
                <span class="fa-20 ml-2">{{ $t("section4.title") }} </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="(item, index) in allImagesArticles.slice(0, 7)"
                :key="index"
                cols="12"
                md="12"
                lg="12"
                class="mb-0 px-2"
              >
                <v-row>
                  <v-col cols="4" class="pt-0">
                    <v-img
                      @click="postDetails(item)"
                      :src="
                        item.images && item.images.length > 0
                          ? item.images[0].thumbnail_path
                          : require('@/assets/No-Image-Placeholder.png')
                      "
                      class="mx-auto main-item-image clickable"
                      width="auto%"
                      height="8em"
                      ><div class="gradient-overlay"></div
                    ></v-img>
                  </v-col>
                  <v-col cols="8" class="pt-0">
                    <span
                      v-if="language == 'en'"
                      class="white--text px-4 dark-panel-1 py-1 fa-12"
                      >{{ item.category_name_english }}</span
                    >

                    <span
                      v-else
                      class="white--text px-4 dark-panel-1 py-1 fa-12"
                      >{{ item.category_name_arabic }}</span
                    >

                    <v-card-title
                      v-if="language == 'en' && item.title_english != null"
                      class="dark--text fa-16 text-truncate pb-0 pt-1 px-0"
                      >{{ item.title_english }}</v-card-title
                    >

                    <v-card-title
                      v-else
                      class="dark--text fa-16 text-truncate pb-0 pt-1 px-0"
                      >{{ item.title_arabic }}</v-card-title
                    >
                    <v-card-text
                      class="align-self-end fa-10 py-0 px-0"
                      cols="12"
                    >
                      <v-icon class="white--text" small
                        >mdi-calendar-account-outline</v-icon
                      >
                      <span class="white--dark fa-10 px-2">{{
                        formatDate(item.created_at)
                      }}</span>
                    </v-card-text>
                    <v-card-text
                      class="align-self-end fa-12 py-0 px-0"
                      cols="12"
                    >
                      <v-icon class="white--text" small>mdi-eye-outline</v-icon>
                      <span class="white--text px-2">{{
                        item.views_count
                      }}</span>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-col cols="12" class="px-0 pt-12">
              <v-btn
                color="white--text dark-banner-4"
                elevation="0"
                @click="allPosts((tag = 'pictures'))"
              >
                {{ $t("section3.show_more") }}
              </v-btn>
            </v-col>
          </v-col>
          <v-col cols="12" md="6" v-if="allVideosArticles != ''">
            <v-row align="center">
              <v-col cols="12" md="auto" class="d-flex align-center px-0">
                <img
                  src="../../assets/PlatformLogo-03.svg"
                  class="my-auto"
                  width="100"
                  height="auto"
                />
                <span class="fa-20 ml-2"> {{ $t("section4.title2") }} </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="(item, index) in allVideosArticles.slice(0, 7)"
                :key="index"
                cols="12"
                md="12"
                lg="12"
                class="mb-0 px-2"
              >
                <v-row>
                  <v-col cols="4" class="pt-0">
                    <v-img
                      @click="postDetails(item)"
                      :src="
                        item.images && item.images.length > 0
                          ? item.images[0].thumbnail_path
                          : require('@/assets/No-Image-Placeholder.png')
                      "
                      class="mx-auto main-item-image clickable"
                      width="auto%"
                      height="8em"
                      ><div class="gradient-overlay"></div
                    ></v-img>
                  </v-col>
                  <v-col cols="8" class="pt-0">
                    <span
                      v-if="language == 'en'"
                      class="white--text px-4 dark-panel-1 py-1 fa-12"
                      >{{ item.category_name_english }}</span
                    >

                    <span
                      v-else
                      class="white--text px-4 dark-panel-1 py-1 fa-12"
                      >{{ item.category_name_arabic }}</span
                    >
                    <v-card-title
                      v-if="language == 'en' && item.title_english != null"
                      class="dark--text fa-16 text-truncate pb-0 pt-1 px-0"
                      >{{ item.title_english }}</v-card-title
                    >
                    <v-card-title
                      v-else
                      class="dark--text fa-16 text-truncate pb-0 pt-1 px-0"
                      >{{ item.title_arabic }}</v-card-title
                    >
                    <v-card-text
                      class="align-self-end fa-10 py-0 px-0"
                      cols="12"
                    >
                      <v-icon class="white--text" small
                        >mdi-calendar-account-outline</v-icon
                      >
                      <span class="white--dark fa-10 px-2">{{
                        formatDate(item.created_at)
                      }}</span>
                    </v-card-text>
                    <v-card-text
                      class="align-self-end fa-12 py-0 px-0"
                      cols="12"
                    >
                      <v-icon class="white--text" small>mdi-eye-outline</v-icon>
                      <span class="white--text px-2">{{
                        item.views_count
                      }}</span>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-col cols="12" class="px-0 pt-12">
              <v-btn
                color="white--text dark-banner-4"
                elevation="0"
                @click="allPosts((tag = 'videos'))"
              >
                {{ $t("section3.show_more") }}
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    const lang = localStorage.getItem("lang") || "ar";

    return {
      info: {
        page: 1,
        keyword: null,
        tag: null,
      },
      json: lang == "ar" ? this.$i18n.messages.ar : this.$i18n.messages.en,
      language: lang,
    };
  },
  computed: {
    ...mapGetters([
      "allImagesArticles",
      "allVideosArticles",
      "getRole",
      "getUser",
    ]),
  },

  watch: {
    currentGet(newVal, oldVal) {
      if (newVal != oldVal) {
        this.info.page = newVal;
        this.info.keyword = this.search;

        this.info.tag = "pictures";
        this.disablePagination = true;
        this.fetchArticlesByTag(this.info)
          .then(() => {
            this.disablePagination = false;
          })
          .catch(() => {
            this.disablePagination = false;
          });

        this.info.keyword = this.search;
        this.info.tag = "videos";
        this.disablePagination = true;
        this.fetchArticlesByTag(this.info)
          .then(() => {
            this.disablePagination = false;
          })
          .catch(() => {
            this.disablePagination = false;
          });
      }
    },
  },
  created() {
    this.disablePagination = true;
    this.info.tag = "pictures";
    this.fetchArticlesByTag(this.info)
      .then(() => {
        this.disablePagination = false;
      })
      .catch(() => {
        this.disablePagination = false;
      });

    this.info.tag = "videos";
    this.fetchArticlesByTag(this.info)
      .then(() => {
        this.disablePagination = false;
      })
      .catch(() => {
        this.disablePagination = false;
      });
  },

  methods: {
    ...mapActions(["fetchArticlesByTag"]),

    showArticleDetails(item) {
      this.articleData = item;
      this.EditArticleDialog = true;
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },

    postDetails(item) {
      this.$router.push({ name: "PostDetails", params: { id: item.id } });
    },

    allPosts(tag) {
      this.$router
        .push({ name: "Posts", params: { category: tag } })
        .catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err;
          }
        });
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.main-card {
  min-height: 100%;
  position: relative;
  overflow: hidden;
  background-color: transparent;
}

.main-card .main-item-image {
  width: 100%;
  height: auto;
  display: block;
  transition: filter 0.3s;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 100, 0.8),
    rgba(0, 0, 100, 0.4)
  ) !important;
}

.main-card .main-item-image:hover {
  filter: brightness(1.2);
}

.gradient-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 200, 0.2),
    rgba(0, 0, 200, 0.2)
  );
  z-index: 1;
  pointer-events: none; /* Ensure the overlay doesn't block interactions with the image */
}

.gradient-overlay-2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 120, 0.2)
  );
  z-index: 1;
  pointer-events: none; /* Ensure the overlay doesn't block interactions with the image */
}

.blog-card {
  min-height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
}

.blog-card .main-item-image {
  width: 100%;
  height: auto;
  display: block;
  transition: filter 0.4s !important;
}

.blog-card:hover .main-item-image {
  filter: brightness(1.2);
}

.white--text {
  color: white !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block; /* Necessary to apply text-overflow in flex container */
}

.text-truncate-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
